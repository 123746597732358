import React from "react";

import { NextPage, NextPageContext } from "next";

import { MetaTags } from "components";
import { BlogHeader, NavigationTab, Pagination, PostCardList } from "components/blog";
import { IBlogPost } from "types/pages";

import { getBlogPostCategories, getBlogPosts, getBlogPostsCount } from "../../../sanity/sanity-utils";

interface IBlogsProps {
    blogPostCategories: string[];
    blogPosts: IBlogPost[];
    totalBlogPostCount: number;
}

const pageSize = 10;

const Blogs: NextPage<IBlogsProps> = ({ blogPostCategories = [], blogPosts = [], totalBlogPostCount = 0 }) => {
    return (
        <>
            <MetaTags
                isBlog
                title="Wayhome Blog | Home Buying Tips, Guides, and Expert Advice"
                description="Discover expert home buying tips, guides, and advice on the Wayhome blog. Learn how to buy a home without a mortgage and take control of your homeownership journey"
            />

            <BlogHeader />

            <NavigationTab blogPostCategories={blogPostCategories} />

            <PostCardList posts={blogPosts} />

            <Pagination totalBlogPostCount={totalBlogPostCount} pageSize={pageSize} />
        </>
    );
};

export const getServerSideProps = async ({ query }: NextPageContext) => {
    const category = (query.currentTab || "all") as string;
    const lastPageSlice = Number(query.lastPageSlice) || 0;

    const blogPostCategories = await getBlogPostCategories();
    const totalBlogPostCount = await getBlogPostsCount(category);
    const blogPosts = await getBlogPosts({ category, lastPageSlice, pageSize });

    return {
        props: {
            blogPostCategories,
            totalBlogPostCount,
            blogPosts,
        },
    };
};

export default Blogs;
